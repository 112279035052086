<div class="container">
  <mat-toolbar>
    <span>Produtos</span>
    <div class="example-spacer"></div>
    <button mat-raised-button color="primary" routerLink="/produtos/cadastrar">Cadastrar Produtos</button>
  </mat-toolbar>

  <mat-card class="mat-elevation-z2 mt-10">
    <table mat-table [dataSource]="products">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <!-- Sku Column -->
      <ng-container matColumnDef="sku">
        <th mat-header-cell *matHeaderCellDef>Sku</th>
        <td mat-cell *matCellDef="let row">{{ row.sku }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </mat-card>
</div>
