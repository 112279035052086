<div class="body">
  <div class="login">
    <mat-spinner *ngIf="loading" class="spinner" diameter="50"></mat-spinner>
    <form *ngIf="!loading" [formGroup]="form" (ngSubmit)="ingressar()">
      <img src="./assets/img/usuario.png" alt="" />
      <h1>Login</h1>
      <mat-form-field class="ancho" appearance="fill">
        <mat-label>Usuário</mat-label>
        <input type="text" matInput autocomplete="off" formControlName="usuario" />
      </mat-form-field>
      <mat-form-field class="ancho" appearance="fill">
        <mat-label>Senha</mat-label>
        <input type="password" matInput autocomplete="off" formControlName="password" />
      </mat-form-field>
      <br />
      <button type="submit" class="ancho button" mat-raised-button color="primary" [disabled]="form.invalid">
        Ingressar
      </button>
    </form>
  </div>
</div>
