<div class="container">
  <mat-toolbar>
    <span>Cadastro Produto</span>
    <div class="example-spacer"></div>
    <button mat-raised-button color="primary" routerLink="/produtos">VOLTAR</button>
  </mat-toolbar>

  <mat-card style="margin-top:10px">
    <form>
      <mat-grid-list cols="4" rowHeight=70px>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field>
            <mat-label>Nome Produto</mat-label>
            <input matInput placeholder="Ex.: Camiseta Azul" [(ngModel)]="product.name" name="name">
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field>
            <mat-label>Quantidade</mat-label>
            <input matInput placeholder="10" [(ngModel)]="amount.quantity" name="quantity">
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <button (click)="createProduct()" mat-raised-button color="primary">Cadastrar</button>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </mat-card>
</div>
