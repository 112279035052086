import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product } from '../components/products/product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  baseUrl = "https://api.prepservice.xyz/products/"

  constructor(
    private http: HttpClient
  ) { }

  read(): Observable<Product[]> {
    return this.http.get<Product[]>(this.baseUrl)
  }

  create(product: Product): Observable<Product[]> {
    return this.http.post<Product[]>(this.baseUrl, product)
  }
}
