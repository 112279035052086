import { Component, OnInit } from '@angular/core';
import { Product, Amount } from '../product.model';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';


@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.css']
})
export class ProductCreateComponent implements OnInit {

  amount: Amount = { quantity: Number('') }

  amounts: Amount[] = []

  product: Product = {
    name: '', sku: '', asin: '', link: '', created_in: '', owner: '', amounts: [
      this.amount
    ]
  }

  products: Product[] = []

  constructor(
    private productService: ProductService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  createProduct(): void {
    console.log(this.product)

    this.productService.create(this.product).subscribe(() => {
      this.productService.read().subscribe(products => {
        this.products = products
        console.log(products)
        this.router.navigate(['/produtos'])
      })
    })
  }
}
